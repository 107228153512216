.list-modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.item-list-modal{
    width: 100%;
}

.select-error {
    border-color: red;
  }
  
  .error-text {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }

.contenedor-buttons-modal{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
    justify-content: center;
}

.contenedor-button-guardar-modal{
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-modal-check{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.contenedor-solicitudes {
    padding: 1rem 1.5rem;
}

.icon-edit {
    color: green;
    text-align: center;
    background-color: #f2f1f1;
    padding: 0.5rem 0.8rem;
    border-radius: 0.3rem;
    cursor: pointer;
    margin-right: 0.5rem;
}

.icon-File {
    color: #3F63AC;
    text-align: center;
    background-color: #f2f1f1;
    padding: 0.5rem 0.8rem;
    border-radius: 0.3rem;
    cursor: pointer;
}

.icon-salida {
    color: #EC5B5B;
    text-align: center;
    background-color: #f2f1f1;
    padding: 0.5rem 0.8rem;
    border-radius: 0.3rem;
    cursor: pointer;
    margin-right: 0.5rem;
}

.icon-firma {
    color: #2A7FEC;
    text-align: center;
    background-color: #E1ECF7;
    padding: 0.5rem 0.8rem;
    border-radius: 0.3rem;
    cursor: pointer;
    margin-right: 0.5rem;
}

.icon-historial {
    color: aquamarine;
    text-align: center;
    background-color: #f2f1f1;
    padding: 0.5rem 0.8rem;
    border-radius: 0.3rem;
    cursor: pointer;
    margin-right: 0.5rem;
}

.contenedor-item {
    transform: translateX(0%);
    text-align: center;
}
.buttonComponent{
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0.5rem 1.5rem;   
    border-radius: 0.3rem;
    width: 100%;
    font-size: clamp(0.75rem, 2.5vw, 1rem);
    cursor: pointer;
    gap: 1rem;
}

.default{
    background-color: #2A9AB6;
    color: #ffffff;
}

.red {
    background-color: rgb(230, 69, 40);
    color: #ffffff;
}

.gris{
    background-color: rgb(147, 145, 145);
    color: #ffffff;
    cursor: default !important;
}

.violeta{
    background-color: #8F3B8C;
    color: #ffffff;
}

.sinbg{
    background-color: rgb(150, 150, 150);
    color: #ffffff;
}

.sfDefault{
    background-color: #fff;
    color: #2A9AB6;
    font-weight: 600;
    font-size: 1.1rem;
}

.link{
    background-color: transparent;
    color: #2A9AB6;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

@media only screen and (max-width: 500px) {
    .buttonComponent{
        font-size: 0.75rem;
    }
}
.contenedorAgregarVisitante {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem;
    background-color: #fff;
}

.tituloAgregarVisitante {
    color: #3F63AC;
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}

.contenedorDatosVisitante {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 2rem;
}

.datosVisitante,
.datosVisitanteEmpleado {
    flex: 1 1 calc(50% - 1rem);
    min-width: 300px;
}

.input-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}

.icon-input {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
    font-size: 1.2rem;
}

.input-container input {
    padding-left: 2.5rem;
    width: 100%;
    height: 40px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    box-sizing: border-box;
}

.input-container input:focus {
    border: none;
    outline: 2px solid #3F63AC;
    border-radius: 0.5rem;
}

.datosVisitanteEmpleado textarea {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    width: 100%;
    height: 80px;
    resize: none;
}

.datosVisitanteEmpleado textarea:focus {
    border: none;
    outline: 2px solid #3F63AC;
}

.contenedorButtonsAgrVisita {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

@media screen and (max-width: 768px) {
    .contenedorDatosVisitante {
        flex-direction: column;
        align-items: stretch;
        gap: 1rem;
    }

    .datosVisitante,
    .datosVisitanteEmpleado {
        flex: none;
        width: 100%;
    }

    .contenedorButtonsAgrVisita {
        flex-direction: column;
        width: 100%;
        gap: 1rem;
    }

    .tituloAgregarVisitante {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .contenedorDatosVisitante {
        gap: 0.5rem;
    }

    .datosVisitante input,
    .datosVisitanteEmpleado textarea {
        font-size: 0.9rem;
        height: 35px;
    }

    .contenedorButtonsAgrVisita {
        gap: 0.5rem;
    }
}
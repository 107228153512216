.contenedorBirthdays{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    font-family: "Poppins", sans-serif;
    gap: 1rem;
}

.contenedorCardBirthday{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.iconBirthday{
    position: absolute;
    font-size: 1rem;
    color: #2A9AB6;
    top: -0.3rem;
    left: 0;

}

.nombreUserBirthday{
    color: #666666;
    font-size: clamp(0.6rem, 3vw, 0.9rem);
}

.birthdayUser{
    color: #8F3B8C;
    font-weight: 600;
    font-size: clamp(0.8rem, 3vw, 1rem);
}

.img-birthdays{
    width: 3rem;
    border-radius: 5rem;
}

.metricDonut{
    width: 250px;
    height: 250px;
}

@media only screen and (max-width: 500px){
    .metricDonut{
        width: 200px;
        height: 200px;
    }
}
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* styles.css */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.rounded-input {
  border-radius: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
}


.button {
  width: 100%;

}
.contenedorInfoObjetivo {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    position: relative;
}

.subtituloObjetivo {
    margin: 0.5rem 0;
}

.NombreObjetivo {
    margin: 0;
    font-size: 2rem;
    color: #8F3B8C;
}

.contenedorInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: start;
    width: 100%;
}

.contenedorTituloPuesto {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
}

.iconoAgregarPuesto {
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #3F63AC;
    color: #fff;
    cursor: pointer;
}

.tituloInfo {
    font-size: 1.5rem;
    color: #3F63AC;
    font-weight: 600;
}

.contenedorInfoDireccion {
    width: 40%;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    box-shadow: 0.2rem 0.2rem 0.2rem #ccc;
    border: 1px solid #ccc;
    padding: 1rem;
    margin-top: 2rem;
}

.contenedorInfoItems {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

.contenedorInfoItem {
    width: 40%;
}

.tituloItemObjetivo {
    font-weight: 600;
    color: #909090;
}

.contenedorPuestos {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contenedorPueso {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    border-radius: 1rem;
    background-color: #ebebeb62;
    padding: 0.5rem 1rem;
}

.contenedorBotonesPuesto {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    gap: 1rem;
}

.buttonEditPuesto {
    color: #3F63AC;
    font-size: 1.2rem;
    cursor: pointer;
}

.buttonDeletePuesto {
    color: #EC5B5B;
    font-size: 1.2rem;
    cursor: pointer;
}

.buttonVolverObj {
    width: 6%;
    background-color: #fff;
    border: none;
    text-align: start;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    color: #a0a0a0;
    transform: translateX(-0.5rem);
    margin-top: 10px;
    font-size: 1rem;
}

.modalDelete {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
}

.iconoDelete {
    color: #EC5B5B;
    font-size: 3rem;
    margin-bottom: 1rem;
}

.contenedorButonsDelete {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
    gap: 1rem;
}
.jornadacontainer {
    display: flex;
    flex-direction: column;
    padding: 0rem 1.5rem;
    align-content: center;
    height: 100%;
}

.titulo-jornada {
    color: #3F63AC;
    padding: 0.5rem;
    font-weight: bold;
    font-size: 2rem;

}

.contenedor-jornada {
    margin-top: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-content: center;
    gap: 1rem;
}

.ellipse {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #979696;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    margin: 0 auto;
}

.ellipse .img-jornada {
    width: 90%;
    height: 90%;
    border-radius: 100%;
    object-fit: cover;
}

.ellipse .btn-img-jornada {
    position: absolute;
    bottom: 0;
    right: -2rem;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: 4rem;
}

.contenedor-texto-jornada {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.titulo-foto-jornada {
    width: 349px;
    height: 31px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    margin: 0.5rem 0;
    color: #8F3B8C;
}

.subtitulo-foto-jornada {
    width: 291px;
    height: 42px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    margin: 0 0 0.8rem 0;
    color: #666666;
}

.buttons-jornada {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    width: 42%;
    margin: 1rem auto;
}

.camara-jornada {
    position: absolute;
    width: 44px;
    height: 44px;
    left: 11.5px;
    top: -30px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}

.icon-camara-jornada {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 11px;
    top: 8px;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 4px;
    color: #2A9AB6;
}

.ellipse-container {
    width: center;
    height: 205px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

@media only screen and (max-width: 1080px) {
    .jornadacontainer {
        padding-left: 1rem;
    }

    .titulo-jornada {
        font-size: 2rem;
        margin: 1rem 1.5rem;
    }
}

@media only screen and (max-width: 850px) {
    .buttons-jornada {
        width: 70%;
    }
}

@media only screen and (max-width: 720px) {
    .jornadacontainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        gap: 32px;
        position: absolute;
        width: 291px;
        height: 468px;
        left: calc(50% - 145.5px);
        top: 100px;
    }

    .titulo {
        width: 291px;
        height: 24px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 0px;
        text-align: center;
        color: #3F63AC;
        margin: 0;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }

    .titulo-foto-jornada {
        width: 291px;
        height: 23px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        margin: 0.5rem 0;
        color: #8F3B8C;
    }

    .subtitulo-foto-jornada {
        width: 291px;
        height: 36px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #666666;
        margin: 0.5rem 0;
    }

    .buttonComponent {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        width: 230px;
        height: 35px;
        background: #2A9AB6;
        border-radius: 12px;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .default {
        background-color: #2A9AB6;
        color: #ffffff;
    }

    .sfDefault {
        background-color: #fff;
        color: #2A9AB6;
        font-weight: 600;
        font-size: 1.1rem;
    }

    .buttons-jornada {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        gap: 12px;
        width: center;
        height: 71px;
        flex: none;
        order: 2;
        flex-grow: 0;
    }

    .ellipse-container {
        width: center;
        height: 225px;
        background-size: cover;
        background-position: center;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .camara-jornada {
        position: absolute;
        width: 44px;
        height: 44px;
        left: 11.5px;
        top: -30px;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
    }

    .icon-camara-jornada {
        position: absolute;
        width: 24px;
        height: 24px;
        left: 11px;
        top: 8px;
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 4px;
        color: #2A9AB6;
    }

    .contenedor-jornada {
        margin-top: 10px;
    }
}
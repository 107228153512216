.navbar-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  height: 12vh;
  left: 0px;
  top: 0px;
  margin: auto;
  z-index: 1;
  position: sticky;

  background: #ffffff;
}

.sub-container-topbar{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.sub-container-topbar h1 {
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 5px;
  margin: auto 0;
}

.alert-contador{
  width: 15px;
  height: 15px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font: bold;
  position: absolute;
  top: 10px;
  transform: translateX(0.8rem);
  background-color: #EC5B5B;
  border-radius: 100%;
  padding: 0 5px;
  border: 2px solid white;
}

.sub-container-name{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-wrap: nowrap;
}

.nav-logo {
  margin: 8px;
  height: 46px;
}

.container-button-user{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.img-company{
  width: 3rem;
  border-radius: 100px;
  margin-right: 22px;
}

.img-mobile{
  margin-left: 20px;
}

.img-mobile img{
  height: 46px;
}

.icon-menu-topbar{
 
  display: none;
}

@media only screen and (max-width: 1080px){
  .navbar-inner{
    height: 10vh;
  }

  .sub-container-topbar h1 {
    font-size: 1rem;
  }

  .icon-menu-topbar{
    display: block;
    border-radius: 100px;
    margin-right: 20px;
    font-size: 1.8rem;
    color: black;
  }

  .img-company{
    display: none;
  }
}

@media only screen and (max-width: 720px) {
  .sub-container-topbar h1{
    font-size: 0.8rem;
  }

  .icon-menu-topbar{
    font-size: 1.3rem;
  }
}

  
  .stepper {
    padding: 3vh 0 !important;
  }
  
  .stepper__step {
    border: 1px solid #a1a3a5 !important;
    cursor: default !important;
  }
  
  .stepper__step + div > span {
    color: #a1a3a5 !important;
  }
  
  .RFS-ConnectorContainer {
    left: calc((-50% + 2em) - 8px) !important;
    right: calc((50% + 2em) - 8px) !important;
  }
  
  .error {
    margin-top: 0;
    color: red;
    font-size: 90%;
  }
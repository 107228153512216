.container-page-calendar{
  height: 82vh;
  display: flex;
  padding: 1rem 2rem;
}

.calenderContainer{
  display: flex;
 
  
}

.title-calendar {
  color: #3F63AC;
}

.custom-calendar {
  width: 500px;
}

@media only screen and (max-width: 720px) {
  

}

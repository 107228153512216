.contenedorTableCalendario{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
}

.contenedorButtonsCalendario{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.contenedorButtonsCalendario button{
    background-color: #fff;
    border: none;
    font-size: 1rem;
    text-decoration: underline;
    color: #3F63AC;
    cursor: pointer;
}

.contenedorButtonsCalendario {
    display: flex;
    justify-content: space-between;
  }
  
  .tableCalendario{
    width: 30vw;
    margin-top: 0;
  }
  
  .tableCalendario th,
  .tableCalendario td {
    border: 1px solid #ccc;
    padding: 3px 0;
    font-size: 12px;
    height: 20px;
    font-weight: 500;
    max-width: 1rem;
  }
.empleadoContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1rem 1.5rem;
}

.rectangulo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start;
}

.contenedor-datos-empleado {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.contenedor-nombre-empleado {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.contenedor-nombre-empleado h2 {
  color: #8F3B8C;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.datos-empleado {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.rectangulo img {
  width: 9rem;
  /* Ancho fijo */
  height: 9rem;
  /* Asegúrate de establecer el mismo alto */
  border-radius: 50%;
  /* Esto hace que sea redondo */
  object-fit: cover;
  /* Para asegurarte de que la imagen se recorte y no se distorsione */
}

.datos-empleado div span {
  font-size: 0.8rem;
  color: #666666;
}

.datos-empleado div p {
  font-size: 0.8rem;
}

.status-activo {
  color: #8F3B8C;
}

.status-inactivo {
  color: tomato;
}

.subdatos-empleado {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1.5rem;
  gap: 2rem;
  border-bottom: 1px solid #ccc;
}

.barra-lateral {
  padding: 10px;
  border-right: 1px solid #ccc;
  margin: 0.4rem 0;
}

.titulo-subdatos-empleado {
  font-size: 1rem;
  color: #666666;
}

.contenedor-item-datos {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 0;
}

.icon-subdatos {
  color: #3F63AC;
  font-size: 0.8rem;
  margin-right: 0.5rem;
}

.titulo-item-datos {
  color: #3F63AC;
  font-size: 0.9rem;
  margin: 0;
}

.contenido-item-datos {
  font-size: 0.8rem;
}

.titulo-asistencia-empleado {
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #2A9AB6;
  margin-top: 1rem;
}

.rectangulo3 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.cuadrado {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 1rem;
  box-shadow: 0.1rem 0.1rem 0.1rem #ccc;
  padding: 0rem 1rem;
}

.progress-value {
  color: #8F3B8C;
}

.progress {
  fill: none;
  stroke: #ccc;
  stroke-width: 0.2rem;
  stroke-dasharray: 110 100;
  transform: rotate(-90deg);
  transform-origin: 50%;
}

.contenedor-progress {
  position: relative;
}

.number-progress {
  font-size: 0.8rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress:nth-child(2) {
  stroke: #8F3B8C;
  stroke-dasharray: var(--porcentual) 100;
}

.contenedor-porcential-titulo h5 {
  font-size: 1rem;
  margin: 0;
}

.contenedor-porcential-titulo p {
  font-size: 0.8rem;
  margin: 0;
}

.rectangulo4 {
  max-width: 76vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: auto;
}

.rectangulo4 .cuadrados {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
}

.rectangulo4 .cuadrados h5 {
  font-size: 1rem;
  color: #666666;
}

.rectangulo4 .cuadrados p {
  font-size: 0.8rem;
}

.itemActive {
  text-align: center;
  background-color: #a3e4a18e;
  color: #2d6a4f;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  padding: 0.3rem 0.5rem;
}

.itemObserved {
  text-align: center;
  background-color: #fff3cd;
  /* Amarillo pastel */
  color: #856404;
  /* Amarillo oscuro */
  border-radius: 0.5rem;
  font-size: 0.8rem;
  padding: 0.3rem 0.5rem;
}

.itemInactive {
  text-align: center;
  color: #EF1C1C;
  background-color: #ef1c1c2e;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  padding: 0.3rem 0.5rem;
}

.itemPending {
  text-align: center;
  color: #e67e22;
  /* Naranja */
  background-color: #fdebd0;
  /* Naranja pastel */
  border-radius: 0.5rem;
  font-size: 0.8rem;
  padding: 0.3rem 0.5rem;
}

.contenedorItemEmpleado {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contenedorButtonsEmpleado {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.contenedor-sin-documentacion {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: #AFAFAF;
}

.contenedorTituloAsistenciaHorario {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.contenedorBotonesFiltroAsistencia {
  display: flex;
  height: 1.5rem;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  background-color: #e3e3e3;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.contenedorBotonesFiltroAsistencia button {
  color: #fff;
  border: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.buttonAsistenciaSelected {
  background-color: #2A9AB6;
}

.buttonAsistenciaNoSelected {
  background-color: #e3e3e3;
}

.icono-editar {
  margin-left: 8px;
  cursor: pointer;
  color: #3f63ac;
}

.icono-save {
  margin-left: 8px;
  cursor: pointer;
  color: green;
}

.icono-cancel {
  margin-left: 8px;
  cursor: pointer;
  color: red;
}

.edit-field {
  display: flex;
  align-items: center;
}

.icono-check {
  margin-left: 8px;
  color: green;
  cursor: pointer;
}

@media only screen and (max-width: 1080px) {
  .rectangulo3 {
    flex-wrap: wrap;
  }

  .rectangulo4 {
    max-width: 100%;
  }

  .rectangulo2 .subdatos-empleado {
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .titulo-asistencia-empleado {
    margin-top: 0.5rem;
  }


}

@media only screen and (max-width: 720px) {
  .rectangulo img {
    display: none;
  }

  .rectangulo {
    width: 90vw;
  }

  .rectangulo3 {
    width: 90vw;
  }

  .contenedor-datos-empleado {
    width: 90%;
  }

  .rectangulo4 {
    flex-direction: column;
    text-align: center;
    width: 80vw;
  }
}

@media only screen and (max-width: 720px) {
  .objetivecontainer {
    width: 80vw;
  }

  .subdatos-empleado {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .barra-lateral {
    display: none;
  }

  .rectangulo3 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .titulo-asistencia-empleado {
    font-size: 1rem;
  }

  .datos-empleado div span {
    font-size: 0.8rem;
  }

  .datos-empleado div p {
    font-size: 0.7rem;
  }
}
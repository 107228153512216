.visitantesContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1rem 1.5rem;
}

.contenedor-item-salida {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.icon-salida {
  color: #EF1C1C;
  text-align: center;
  background-color: #f2f1f1;
  padding: 0.5rem 0.8rem;
  border-radius: 0.3rem;
  cursor: pointer;
}

.contenedor-item-eliminar {
  transform: translateX(0%);
  text-align: center;
}

.ingreso-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  gap: 10px;

  margin: 0 auto;
  width: 77px;
  height: 26px;

  background: rgba(0, 210, 202, 0.1);
  border-radius: 12px;

  flex: none;
  order: 7;
  flex-grow: 0;

  width: 48px;
  height: 14px;

  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;

  color: #00D2CA;
}

.ingreso-container.salida {
  background: rgba(255, 0, 0, 0.1);
  color: red;
}

th,
td {
  text-align: center;
}
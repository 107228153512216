.miperfil-container {
   padding: 0rem 1.5rem;
   flex-direction: column;
   position: relative;
}

.subcontenedor-miperfil {
   width: 70vw;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   margin-top: 1rem;
}

.contenedor-imgPerfil {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 2rem;
   margin-top: -30px;
   margin-right: 100px;
}

.img-miperfil {
   width: 225px;
   height: 225px;
   border-radius: 50%;
   background-size: cover;
   flex: none;
   order: 0;
   flex-grow: 0;
}

.contenedor-button-img-perfil {
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
}

.contenedor-datos-miperfil {
   width: 40%;
   display: flex;
   flex-direction: column;
   align-items: start;
   justify-content: center;
}

.contenedor-datos-perfil {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-top: -20px;
}

.contenedor-dato-perfil {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   gap: 1rem;
}

.icon-miprofile {
   color: #666666;
}

.contenedor-dato-perfil p {
   color: #666666;
   cursor: default;
}

.contenedor-btnEdit {
   display: flex;
   flex-direction: column;
   gap: 1rem;
   margin-top: 1.5rem;
}

.button-edit {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   padding: 12px 24px;

   width: 296px;
   height: 45px;

   background-color: #F3F3F3;
   border: none;
   border-radius: 12px;

   color: #000000;
   font-family: 'DM Sans', sans-serif;
   font-weight: 700;
   font-size: 16px;
   line-height: 18px;
   cursor: pointer;
   transition: background-color 0.3s ease;
}

.button-edit:hover {
   background-color: #E0E0E0;
}

.icon-miprofile {
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 0px;
   width: 16px;
   height: 16px;
   flex: none;
   order: 0;
   flex-grow: 0;
   margin-right: 12px;
}

/* NUEVOS ESTILOS PARA LOS INPUTS */

.input-miperfil {
   width: 100%;
   padding: 8px;
   margin: 8px 0;
   box-sizing: border-box;
   border: 1px solid #ccc;
   border-radius: 4px;
   font-size: 16px;
}

.direccion-inputs {
   display: flex;
   gap: 8px;
}

/* RESPONSIVIDAD */

@media only screen and (max-width: 720px) {
   .container-page {
      width: 100vw;
      min-height: 100vh;
      transform: translate(0, 0vh);
      transition: none;
      overflow: auto;
   }

   .container-page-close {
      width: 100vw;
      transform: translate(0, 0vh);
      transition: none;
      overflow: auto;
   }

   .contenedor-imgPerfil {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      margin-top: 1%;
      padding: 0;
      gap: 24px;
      width: 296px;
      height: 332px;
      flex: none;
      align-self: stretch;
      flex-grow: 0;
   }

   .miperfil-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      gap: 48px;
      position: absolute;
      width: 296px;
      height: 815px;
      left: 32px;
      top: 103px;
   }

   .titulo {
      margin-top: -50px;
   }

   .subcontenedor-miperfil {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      gap: 32px;
      width: 296px;
      height: 388px;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      margin-top: 90px;
   }

   .contenedor-datos {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      gap: 48px;
      width: 296px;
      height: 379px;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
   }

   .contenedor-datos-perfil {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      gap: 0px;
      width: 214px;
      height: 286px;
      flex: none;
      order: 0;
      flex-grow: 0;
   }

   .img-miperfil {
      width: 225px;
      height: 225px;
      border-radius: 50%;
      background-size: cover;
      flex: none;
      order: 0;
      flex-grow: 0;
   }

   .buttonComponent {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      gap: 8px;
      width: 96%;
      height: 34px;
      border-radius: 12px;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      color: #FFFFFF;
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      cursor: pointer;
   }

   .default {
      background-color: #2A9AB6;
      color: #ffffff;
   }

   .red {
      background: rgba(239, 28, 28, 0.1);
      color: #ffffff;
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: red;
   }

   .button-edit {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 12px 24px;
      width: 296px;
      height: 45px;

      background-color: #F3F3F3;
      border: none;
      border-radius: 12px;
      color: #000000;
      font-family: 'DM Sans', sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      cursor: pointer;
      transition: background-color 0.3s ease;
   }

   .button-edit:hover {
      background-color: #E0E0E0;
   }

   .icon-miprofile {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      width: 16px;
      height: 16px;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-right: 12px;
   }
}
.contenedorObjetivo {
    padding: 1rem 1rem;
    align-content: center;
    height: 90%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.input-container-objetivo {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.input-container-objetivo input {
    width: 100%;
    padding: 10px 15px 10px 40px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-size: 1rem;
    color: #333;
}

.input-container-objetivo .icon {
    position: absolute;
    left: 15px;
    color: #bdbdbd;
    font-size: 1.2rem;
}

.input-container-objetivo input:focus {
    border-color: #6200ea;
    outline: none;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 50%;
}

.selectObjetivo {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-size: 1rem;
    color: #333;
    margin-bottom: 1rem;
}

.selectObjetivo:focus {
    border-color: #6200ea;
    outline: none;
}

.contenedorButtonsGuardarObjetivo {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 4rem;
}

.cancelButton {
    width: 192px;
    background-color: #ffffff;
    border: 1px solid #2A9AB6;
    color: #2A9AB6;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.cancelButton:hover {
    background-color: #e6f7fc;
}

.submitButton {
    width: 300px;
    background-color: #8F3B8C;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
}

.submitButton:hover {
    background-color: #7b336e;
}

.input-error {
    border: 1px solid red;
    background-color: #ffe6e6;
}

.error-message {
    color: red;
    font-size: 0.8rem;
    margin-top: 5px;
}

@media only screen and (max-width: 720px) {
    .row {
        flex-direction: column;
        gap: 0.5rem;
    }

    .contenedorButtonsGuardarObjetivo {
        flex-direction: column;
        gap: 1rem;
        margin-top: 1.5rem;
    }

    .cancelButton {
        width: 100%;
        font-size: 0.9rem;
        padding: 12px;
    }

    .submitButton {
        width: 100%;
        font-size: 0.9rem;
        padding: 12px;
    }
}
.contenedor-modal-alert{
    position: fixed;
    width: 40vw;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    z-index: 2;
    font-size: 1.2rem;
}

.contenedor-modal{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contenedor-modal h4{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0.4rem 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: start;
    text-decoration: underline 2px;
}

.contenedor-modal p{
    margin: 0.2rem 1rem;
    font-size: 0.8rem;
    text-align: start;
}

.bar-succes{
    background-color: rgb(41, 245, 92);
    width: 100%;
    height: 0.2rem;
}

.bar-worning{
    background-color: rgb(238, 245, 41);
    width: 100%;
    height: 0.2rem;
}

.bar-error{
    background-color: rgb(245, 41, 41);
    width: 100%;
    height: 0.2rem;
}

.succes{
    background-color: rgb(219, 250, 232);
    color: rgb(29, 177, 66);
}

.warning{
    background-color: rgb(248, 250, 219);
    color: rgb(190, 195, 37);
}

.error{
    background-color: rgb(250, 219, 219);
    color: red;
}

@media only screen and (max-width: 720px) {
    .contenedor-modal-alert{
        font-size: 0.8rem;
        width: 60vw;
    }
}
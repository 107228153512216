.contenedorRegistroEmpleado{
    padding: 1rem 1.5rem;
    position: relative;
}

.tituloNombreEmpleado{
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    font-size: clamp(1rem, 4vw, 1.5rem)
}

.nameRegistro{
  color: #8F3B8C;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: clamp(1rem, 4vw, 1.5rem)
}

.listadoPuestoRegistros{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.contenedorPuestoRegistro{
    width: 70%;
    border-radius: 1rem;
    box-shadow: 0.2rem 0.2rem 0.2rem #ccc;
    border: 1px solid #ccc;
    padding: 1rem 1.5rem;
}

.filaRegistro{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid #ccc;
}

.contenedorFilaRegistroHorario{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.filaRegistroHorario{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.nombrePuesto{
    font-size: 1.4rem;
    color: #898888;
}

.textoFecha{
    color: #898888;
    font-weight: 600;
}

.iconHorarioCortado{
    padding: 0.3rem 0.5rem;
    background-color: #3F63AC;
    border-radius: 0.3rem;
    color: #fff;
    cursor: default;
  }

.contenedorButtonsRegistro{
    display: flex;
    flex-direction: row;
    gap: 0.5rem
}

.buttonEditRegister{
    color: #3F63AC;
    cursor: pointer;
}

.buttonDeleteRegister{
    color: #EC5B5B;
    cursor: pointer;
}
body {
  padding-top: 80px; 
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #c6c7c7;
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar:vertical{
  width:10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #19212b;
  border-radius: 20px;
  border: 1px solid #f1f2f3;
 
}

table {
  font-family: "Poppins", sans-serif;
}

thead tr{
  background-color: #eae9e9;
  
}

thead tr th{
  text-align: center;
  color: #000000;
  font-weight: 500;
  padding: 0.5rem 0;
  border-radius: 0.5rem;

}


td{
  height: 2.5rem;
  padding-left: 0.5rem;
}



span{
  cursor: default;
}

.hidden{
  visibility: hidden;
}

.column{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem
}

.titulo{
  margin: 0.5rem 0.5rem 1.5rem 0.5rem;
  font-family: "Poppins", sans-serif;
  color: #3F63AC;
  font-weight: 500;
  font-size: 2.3rem;
}

.pointer{
  cursor: pointer;
}

.poppins-xs {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-m {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-xl {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.dm-sans{
  font-family: "DM Sans", sans-serif;
}

.contenedorUpdateRecord{
    position: absolute;
    width: 50vw;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0.2rem 0.2rem 0.2rem #ccc;
    border: 1px solid #ccc;
    padding: 1rem 1.5rem;
}

.contenedorHorariosUpdate{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.iconHorarioCortadoUpdate{
    padding: 0.3rem 0.5rem;
    background-color: #3F63AC;
    border-radius: 0.3rem;
    color: #fff;
    cursor: pointer;
}
.contenedorMapa{
width: 100%;
}

.places-container {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 300px;
  }
  
  .map-container {
    width: 100%;
    height: 50vh;
    border-radius: 0.5rem;
    border: 2px solid #ccc;
  }
  
  .combobox-input {
    width: 100%;
    padding: 0.5rem;
  }

  .contenedorInputMap{
    width: 60%;
    margin-bottom: 1rem;
  }

  .inputMap{
    width: 100%;
    padding: 0.5rem 0.5rem;
    border-radius: 0.4rem;
    border: 1.5px solid #ccc;
    font-size: 1rem;
  }

  .itemBusquedaMap{
    padding: 0.8rem 0.5rem;
    border-bottom: 1px solid #ccc;
  }

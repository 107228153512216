/* Estilos personalizados para el modal de la tabla */
.custom-modal .modal-dialog {
    max-width: 600px;
    margin: 0;
    z-index: 1050;
    /* Asegura que el modal esté encima de todo */
    position: relative;
}

.custom-modal .modal-content {
    padding: 20px;
    background-color: #ffffff;
    /* Fondo blanco para el modal */
    border-radius: 8px;
    /* Bordes redondeados */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Sombra suave */
}

.custom-modal .modal-body {
    padding: 0px;
    background-color: #ffffff;
    /* Fondo blanco para el contenido del modal */
}

.custom-modal .modal-header,
.custom-modal .modal-footer {
    background-color: #ffffff;
    /* Asegura que el header y footer también tengan fondo blanco */
}

.modal-backdrop {
    z-index: 1040;
    /* Fondo oscuro del modal */
    background-color: rgba(0, 0, 0, 0.5);
    /* Fondo oscuro translúcido */
}

/* Estilos para la tabla */
.custom-modal .modal-body table {
    background-color: #ffffff;
    /* Fondo blanco para la tabla */
    border-radius: 4px;
    /* Bordes redondeados para la tabla */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Sombra suave */
}

.custom-modal .modal-body table th,
.custom-modal .modal-body table td {
    background-color: #f9f9f9;
    /* Fondo ligeramente gris para las celdas */
    border: 1px solid #ddd;
    /* Bordes suaves */
}

.text-center {
    text-align: center;
}

.table-preview-notificaciones {
    max-height: 220px;
    overflow-y: auto;
    border: 1px solid #ddd;
    margin-top: 0px;
    border-radius: 4px;
    background-color: #fff;
}

.table-preview-notificaciones table {
    width: 100%;
    border-collapse: collapse;
}

.table-preview-notificaciones th {
    background-color: #f0f0f0;
    font-weight: bold;
    padding: 8px;
    font-size: 13px;
    color: #333;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.table-preview-notificaciones td {
    padding: 8px;
    font-size: 10px;
    color: #555;
    border-bottom: 1px solid #eee;
}

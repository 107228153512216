.contenedorHome{
  padding: 1rem 1.5rem;
  overflow-x: hidden;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.holaTitulo{
  font-size: clamp(1.2rem, 3vw, 2rem);
}

.holaTituloColor{
  font-weight: 700;
  color: #8F3B8C;
  padding-right: 0.5rem;
}

.nombreTitulo{
  color: #666666;
}

.titleCard{
  font-size: clamp(0.7rem, 3vw, 1rem);
  color: #3F63AC;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.contenedorCardsHome{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-items: stretch;
  gap: 0.5rem 1rem;
  overflow-x: hidden;
}

.iconCardHome{
  color: #2A9AB6;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #2a9ab63d;
}

.iconCardHome2{
  color: #8F3B8C;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #8F3B8C3d;
}

.iconCardHome3{
  color: #00D2CA;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #00D2CA3d;
}

.iconCardHome4{
  color: #EC5B5B;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #EC5B5B3d;
}

.iconCardHome5{
  color: #3F63AC;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #3F63AC3d;
}

.tituloPorcentaje{
  font-size: clamp(0.5rem, 3vw, 0.8rem);
  margin: 0;
  padding: 0;
}

.subtituloPorcentaje{
  margin: 0;
  padding: 0;
}

.contenedorPorcentaje{
  display: flex;
  flex-direction: row;
}

.contenedorFichaPorcentaje{
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.5rem;
}

.titleWithIcon {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Espacio entre texto e ícono */
  color: #3F63AC;
}

.contenedorRegistro{
    padding: 1rem 1.5rem;
    position: relative;
}

.modalAgregarPeriodo{
  position: absolute;
  right: 3rem;
  background-color: #fff;
  padding: 0.5rem;
  animation-name: animation;
  animation-duration: 0.3s;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  margin-top: 0.5rem;
}

@keyframes animation {
  from {
      opacity: 0;
      transform: translateY(-1rem);
  }
  to {
      opacity: 1;
      transform: translateY(0rem);
  }
}



.contenedorTituloModalCalendario{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.contenedorTituloModalCalendario button{
  border: none;
  background-color: #fff;
}

.iconCheckCalendario{
  color: #fff;
  font-size: 1.2rem;
  background-color: #3F63AC;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
}

.contenedorFormRegistros{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.contenedorUnoRegistro{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.5rem;
}

.contenedorDatosRegistros{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contenedorDosRegsitros{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.inputRegistro{
  padding: 0.5rem 0.3rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  border: 2px solid #ccc;
}

.contenedorDatosCalendarioRegistro{
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 3rem;
}

.ContenedorDatosCalendario{
  width: 100%;
}

.tituloAgregarPeriodo{
  font-size: 1.4rem;
  text-align: center;
  color: #3F63AC;
}

.contenedorButtonAgregarPuesto{
  margin-top: 1rem;
}

.contenedorTablaAgregarRegistro{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contenedorTablaAgregarRegistro table{
  width: 80%;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 1rem;
}

.contenedorTablaAgregarRegistro table thead th{
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}

.visibility{
  visibility: hidden;
}

.contenedorPeriodos{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.contenedorPeriodo{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 0.3rem;
}

.iconAgregarPeriodos{
  padding: 0.3rem 0.5rem;
  background-color: #3F63AC;
  border-radius: 0.3rem;
  color: #fff;
  cursor: pointer;
}

.iconEliminarHorarioCortado{
  padding: 0.3rem 0.5rem;
  background-color: #ccc;
  border-radius: 0.3rem;
  color: #fff;
  cursor: pointer;
}

.inputTimeGris{
  visibility: hidden;
}

.contenedorBtnGuardarRegistro{
  width: 20rem;
  margin: 1rem auto 0 auto;
}

.contenedorFormCalendario{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: start;
  margin-bottom: 1rem;
}

.aclaracionSelectRegistro{
  font-size: 0.7rem;
  color: #828282;
}

.contenedorDiaRegistro{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.5rem
}

.aclaracionHorarioNocturno{
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  background-color: #3F63AC;
  color: #fff;
  font: bold;
  border-radius: 5rem;
}
.container-layout{
    display: flex;
    flex-direction: row;
}

.container-page{
    
    margin: 1rem;
    transform: translate(16.3vw, 0vh );
    min-width:  81.25vw;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
    min-height: 82.5vh;

    transition: width 0.5s step-start;
}

.container-page-close{
    margin: 1rem;
    transform: translate(5.76vw, 0vh );
    width:  91.8vw;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
    min-height: 82.5vh;

    transition: width 0.2s step-start;
}

@media only screen and (max-width: 1080px) {
    .container-page{
        width:  100vw;
        transform: translate(0, 0vh);
        transition: none;
    }

    .container-page-close{
        width:  100vw;
        transform: translate(0, 0vh);
        transition: none;
    }
}

@media only screen and (max-width: 500px) {

}
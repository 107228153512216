.contenedorCardHome{
    padding: 1rem;
    background-color: #cccccc2c;
    border-radius: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex-basis: calc(17% - 1rem);
}

.contenedorCardHome2{
    padding: 1rem;
    background-color: #cccccc2c;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.contenedorDatosCardHome{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.NumeroCardHome{
    font-size: clamp(1rem, 2.2vw, 1.5rem);
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.textCardHome{
    font-size: clamp(0.5rem, 1.2vw, 0.8rem);
    color: #AFAFAF;
    margin: 0;
    padding: 0;
}

.loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: 
      radial-gradient(farthest-side,#3F63AC 94%,#0000) top/8px 8px no-repeat,
      conic-gradient(#0000 30%,#3F63AC);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation: l13 1s infinite linear;
  }
  @keyframes l13{ 
    100%{transform: rotate(1turn)}
  }
  .sidebar {
    width: 16.7vw;
    height: 89vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-right: 2px solid #ccc;
    position: fixed;
    top: 11vh;
    bottom: 0px;
    transition: width 0.2s ease;
  }

  .contenedor-navbar-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
  }

  .sidebar.open {
    width: 6.3vw;
  }

  .item-navbar {
    min-width: 2.2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-decoration: none;
    color: #5c5c5c;
    font: bold;
    font-size: 0.83rem;
    border-radius: 0.5rem;
    color: #2A9AB6;
  }


  .item-navbar .icon-flag {
    font-size: 1.2rem;
    padding-left: 1.5rem;
    margin: 0 0.2rem;
  }

  .icon-content {
    color: #2A9AB6;
  }

  .nav-links {
    padding: 1rem;
    color: #7a7a7a;
    font-size: 1.1rem;
  }

  .nav-link {
    display: block;
    color: #7a7a7a;
    text-decoration: none;
    padding: 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;

  }

  .logout-button {
    background: none;
    border: none;
    color: #7a7a7a;
    font-size: 1rem;
    cursor: pointer;
    font-size: 0.9rem;
  }

  .icon {
    font-size: 24px;
    margin-right: 10px;
  }

  .main-content {
    flex-grow: 1;
    margin-left: 250px;
  }

  .content {
    padding: 2rem;
  }

  .bg-div {
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all 0.6s ease-in-out;
  }

  .bg-div.active {
    border-radius: 0 0 80% 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


  .sidebar-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-top: 1px solid #ccc;
    margin: 0;
    padding: 1rem 0;
  }

  .icon-flag-session {
    font-size: 1rem;
    color: #7a7a7a;
    cursor: pointer;
    transform: rotate(180deg);
  }

  .list-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-decoration: none;
    padding-left: 0.5rem;
    box-sizing: border-box;
  }

  .list-menu-close {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
  }

  .list-menu-close .icon-flag {
    padding: 0.5rem 0.2rem;
  }

  .inactive {
    display: none;
  }

  .active {
    justify-content: start;
    background-color: #8F3B8C;
    color: #fff;
    width: 90%;
  }

  .icon-flag {
    font-size: 1.2rem;
    cursor: pointer;
  }

  .icon-flag.close {
    transform: translate(4rem, 0rem);
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .icon-flag.open {
    padding-bottom: 0.5rem;
    padding-top: 1rem;
  }

  .pending-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #e53935;
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: 0.5rem;
  }

  @media only screen and (max-width: 1080px) {
    .icon-flag.open {
      display: none;
    }

    .icon-flag.close {
      display: none;
    }

    .sidebar.open {
      display: none;
    }

    .sidebar {
      position: fixed;
      right: 0;
      top: 10vh;
      height: 85vh;
      width: 30vw;
      z-index: 999999;
      padding-top: 2rem;
    }
  }

  @media only screen and (max-width: 720px) {
    .sidebar {
      width: 45vw;
    }
  }

  @media only screen and (max-width: 500px) {
    .sidebar {
      width: 55vw;
      height: 60vh;
    }
  }

  @media only screen and (max-width: 400px) {
    .sidebar {
      width: 65vw;
    }
  }
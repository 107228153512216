.contenedor-modal-suspensiones {
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.contenedor-fecha-inicio,
.selectContainer,
.contenedor-observaciones {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.customTextField,
.customSelect-suspensiones {
    width: 100%;
}

.customTextField input,
.customSelect-suspensiones {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-size: 1rem;
    color: #333;
}

.customTextField input:focus,
.customSelect-suspensiones:focus {
    border-color: #6200ea;
    outline: none;
}

.button-suspension {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
}

.btn-cancelar {
    width: 192px;
    background-color: #ffffff;
    border: 1px solid #2A9AB6;
    color: #2A9AB6;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn-cancelar:hover {
    background-color: #e6f7fc;
}

.btn-editar {
    width: 192px;
    background-color: #8F3B8C;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
}

.btn-editar:hover {
    background-color: #7b336e;
}

.btn-agregar {
    width: 300px;
    background-color: #8F3B8C;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
}

.btn-agregar:hover {
    background-color: #7b336e;
}

.row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.half-width {
    width: 48%;
}


@media only screen and (max-width: 720px) {
    .contenedor-modal-suspensiones {
        width: 100%;
        padding: 1rem;
    }

    .button-suspension {
        flex-direction: column;
        gap: 1rem;
    }

    .btn-cancelar,
    .btn-editar,
    .btn-agregar {
        width: 100%;
        font-size: 0.9rem;
        padding: 12px;
    }
}
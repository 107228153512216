.contenedorOptions{
    width: 99.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 0.3rem;
}

.contenedorInputOptionRegisters{
    width: 50%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.inputComponentRegisters{
    width: 100%;
    padding: 0.5rem 0.8rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    border: 2px solid #ccc;
}

.inputComponent{
    width: 50%;
    padding: 0.5rem 0.8rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    border: 2px solid #ccc;
}

.inputMothComponent{
    width: 60%;
}

.icon-options{
    color: #ccc;
    padding: 0.8rem;
    border: 0.5rem;
    font-size: 0.8rem;
    border: 2px solid #ccc;
    border-radius: 1rem;
    transition: all 0.3s ease;
}

.icon-options-close{
    color: #f05353;
    padding: 0.8rem;
    border: 0.5rem;
    font-size: 0.8rem;
    border: 2px solid #f05353;
    border-radius: 1rem;
    transition: all 0.3s ease;
}

.button-icons-options{
    border: none;
    background-color: #fff;
    cursor: pointer;
}

.icon-options:hover{
    background-color: #2A9AB6;
    border: 2px solid #2A9AB6;
    color: #fff;
}

.icon-options-close:hover{
    background-color: #f05353;
    border: 2px solid #f05353;
    color: #fff;
}
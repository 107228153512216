.contenedorAgregarPuesto{
  padding: 1.5rem 2rem;
}

.contenedorFormAgregarPuesto{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 1rem;
  margin-top: 1rem;
}

.contenedorInputAgregarPuesto{
 width: 90%;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
}

.tituloPuesto{
  font-size: 2.2rem;
  margin: 0;
  color: #2A9AB6;
}

.inputAgregarPuesto{
  width: 80%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1.5px solid #ccc;
  border-radius: 0.4rem;
}

.textAgregarPuesto{
  font-size: 1.1rem;
  font-weight: 600;
  color: #8F3B8C;
}

.textLatLgn{
  font-size: 1rem;
  font-weight: 400;
  color: #000;
}

.contenedorBotonesAgregarPuesto{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
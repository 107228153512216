.login {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.contenedor-login-img {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
  background: linear-gradient(to bottom right, #2A9AB6 0%, #a33fac 100%);
}

.rectangulo-login {
  width: 70%;
  min-height: 90%;
  margin: auto;
  background-color: #ccc5;
  border-radius: 2rem;

}

.logo-empresa {
  width: 10%;
  position: absolute;
  top: 5%;
  right: 20.5%;
}

.imagen-login {
  position: absolute;
  width: 110%;
  margin: auto;
  bottom: 0;
  left: -3%;
}

.text-login {
  width: 60%;
  margin: auto;
  color: #fff;
  font-size: clamp(1rem, 1.8vw, 1.8rem);
  position: absolute;
  left: 21%;
  top: 12%;
  text-align: center;
}

.contenedor-form-login {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}


.title-login {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #2A9AB6;
  text-align: center;
  font-weight: 700;
  margin: 0;
  padding: 0;
  size: 32px;
}

.description-login {
  font-size: clamp(1rem, 1.8vw, 1.8rem);
}

.rounded-input {
  border-radius: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
}

.input-group {
  position: relative;
  width: 414px;
  height: 46px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 12px;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 10px;
}

.input-login {
  width: 100%;
  border: none;
  outline: none;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #979797;
  padding-right: 40px;
}

.icon-input-wrapper {
  position: absolute;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.icon-input-login {
  font-size: 14px;
  font-weight: 900;
  color: #979797;
}

.button {
  width: 100%;

}

.recuperarContraseña {
  border: none;
  background-color: #fff;
  width: 100%;
  margin-top: 0.8rem;
  color: #8d8d8d;
}

.input-group-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-login {
  background: white;
}

.sub-container {
  margin-top: 5px;
  margin-left: auto;
  margin-right: 800px;
}

.copyright {
  position: absolute;
  width: 272px;
  top: 88.81%;
  bottom: 9.66%;
  font-family: 'DM Sans', sans-serif;
  /* Asegúrate de que la fuente esté disponible */
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #AFAFAF;
}

@media only screen and (max-width: 720px) {

  .contenedor-login-img {
    display: none;
  }

  .contenedor-form-login {
    width: 100%;
  }

  .logo-image-Movil {
    display: block;
    position: relative;
    top: -100px;
    margin-left: auto;
    margin-right: auto;
    max-width: 230px;
    max-height: 130px;
  }

  .login-input {
    margin-top: 10px;
  }

  .input-group {
    width: 60vw;
    margin: 10px;

  }

  .custom-button {
    width: 272px;
    height: 40px;
    background-color: #2A9AB6;
    color: white;
    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 25px;
  }

  .login-text {
    --f7-theme-color: white;
  }

  .logo-empresa {
    width: 18%;
    position: absolute;
    top: 1%;
    right: 42%;
  }

  .img-mobile {
    text-align: left;
    margin-left: -60px;
  }

  .input-group {
    position: relative;
    width: 414px;
    height: 46px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 12px;
    padding: 0 16px;
    box-sizing: border-box;
    margin: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .input-group {
    width: 80vw;
    margin: 10px;
  }

  .button {
    width: 80vw;
  }

  .logo-empresa {
    width: 7rem;
    right: auto;
    margin-top: 110px;
  }

  .copyright {
    display: none;
  }
}
.contenedorPagination{
    width: 98%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 0.5rem;
}

.buttonPagination{
    background-color: transparent;
    border: none;
    font-size: 1rem;
}

.buttonAzulPagination{
    color: #4d4c4c;
}

.buttonGrisPagination{
    color: #ccc;
}
.asistcontainer {
    padding: 1rem 1.5rem;
}

.contenedor-serch-asistencia {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.menu-option-asistencia {
    border: none;
    background-color: transparent;
    font-size: 0.7rem;
}

.modalCalendar {
    position: absolute;
    right: 5px;
    margin-top: 0.5rem;
    animation-name: animation;
    animation-duration: 0.3s;
}

.modalExport {
    position: absolute;
    right: 5px;
    margin-top: 0.5rem;
    animation-name: animation;
    animation-duration: 0.3s;
    background-color: #fff;
    padding: 1rem;
    border-radius: 1rem;
}

.contenedorDesadeHasta {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.contenedorRarioButtons {
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
}

.contenedorRadio {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: -0.5rem;
}

.contenedorRadio P {
    font-size: clamp(0.8rem, 1vw, 1rem);
    margin: 0.2rem 0;
}

.inputDate {
    color: #2A9AB6;
    text-align: center;
    visibility: visible;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #2A9AB6;
}

.inputSelectEmpleado {
    margin-top: 0.5rem;
}


.contenedorBotonesFiltroEmpleado {
    background-color: #ECECEC;
    padding: 0.5rem;
    display: flex;
    gap: 0.5rem;
    border-radius: 0.5rem;
}

.contenedorBotonesFiltroEmpleado button {
    padding: 0.5rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
}

.buttonFiltroGris {
    background-color: #ECECEC;
    color: #000;
}

.buttonFiltroAzul {
    background-color: #fff;
    color: #2A9AB6;
}

.spanEgreso {
    color: #3F63AC;
    background-color: #3f63ac25;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    border-radius: 0.5rem;
}

.spanAusente {
    color: #EF1C1C;
    background-color: #ef1c1c2f;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    border-radius: 0.5rem;
}

.spanIngreso {
    color: #00D2CA;
    background-color: #00d2cb1e;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    border-radius: 0.5rem;
}

.spanAusente {
    color: #FFC107;
    background-color: #ffc1072f;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    border-radius: 0.5rem;
}

.align-middle {
    text-align: center;
}

table {
    width: 100%;
    margin-top: 0.5rem;

}

@keyframes animation {
    from {
        opacity: 0;
        transform: translateY(-1rem);
    }

    to {
        opacity: 1;
        transform: translateY(0rem);
    }
}


@media only screen and (max-width: 720px) {
    .mobile-hidden {
        display: none !important;
    }

    .asistcontainer {
        width: 100%;
        padding-top: 0%;
        margin-left: 3%;
        align-content: center;
    }

}
.contenedorTardanzas{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1rem;
    font-family: "Poppins", sans-serif;
}

.contenedorCardTardanzas{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.contenedorDatosTardanza{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-content: center;
}

.nombreTardanza{
    padding: 0;
    margin: 0;
    color: #666666;
}

.txthoraTardanza{
    padding: 0;
    margin: 0;
    color: #AFAFAF;
    font-size: clamp(0.5rem, 3vw, 0.75rem);
}

.horaTardanza{
    margin-bottom: -5px;
    padding-bottom: 0;
    color: #8F3B8C;
    font-weight: 600;
    font-size: clamp(0.7rem, 3vw, 0.9rem);
}

.img-margin{
    margin: 0;
    padding: 0;
    width: 3rem;
    border-radius: 5rem;
}
.modal-overlay-employee {
    position: fixed;
    top: 0;
    /* Asegura que el modal esté en la parte superior */
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Asegura que el modal esté al frente de otros elementos */
}

.modal-content-employee {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    width: 600px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;

    position: relative;
    margin: 50px auto;
    top: -60px;
}

.modal-header-employee {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0px;
}

.modal-header h2 {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.close-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #555;
}

.modal-body {
    margin-top: 15px;
}

.file-upload {
    display: block;
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
}

#file-upload {
    margin-left: 10px;
}

.select-data-option {
    margin-top: 10px;
    padding: 5px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
}

.template-download {
    margin: 15px 0;
    font-size: 14px;
    color: #007bff;
    cursor: pointer;
    text-decoration: none;
}

.template-download:hover {
    text-decoration: underline;
}

.table-preview {
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ddd;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #fff;
}

.table-preview table {
    width: 100%;
    border-collapse: collapse;
}

.table-preview th {
    background-color: #f0f0f0;
    font-weight: bold;
    padding: 8px;
    font-size: 12px;
    color: #333;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.table-preview td {
    padding: 8px;
    font-size: 12px;
    color: #555;
    border-bottom: 1px solid #eee;
}

.description {
    margin-top: 20px;
    font-size: 12px;
    color: #555;
    line-height: 1.5;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
}

.confirm-button {
    background-color: #4CAF50;
    color: white;
    padding: 8px 16px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.confirm-button:hover {
    background-color: #45a049;
}

.cancel-button {
    background-color: #d9534f;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cancel-button:hover {
    background-color: #c9302c;
}
.contenedor-editEmpleado {
    margin: 0px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;
    border-radius: 0rem;
    box-shadow: 0.2rem 0.2rem 0.2rem #ccc;
    z-index: 10;
    border: 1px solid #ccc;
    padding: 1rem 1.5rem;
    height: 114%;
    width: auto;
}

.titulo-edit-empleado {
    width: 100%;
    margin: 0 auto 1rem auto;
    color: #3F63AC;
    text-align: start;
    font-size: 2.2rem;
}

.subtitulo-edit-empleado {
    font-size: 1rem;
    color: #8F3B8C;
}

.input-edit-empleado {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 0.2rem 0.2rem;
}

.input-edit-empleado:focus {
    border-color: #3a3a3a;
    outline: 0;
    box-shadow: none;
}

.borde-bottom-edit {
    border-left: 1px solid #8F3B8C;
    padding-left: 1rem;
    margin-top: 0.5rem;
}

.contenedorDireccion {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.contenedor-button-edit-empleado {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40rem;
    /*padding: 2rem 0;*/
}
/* Estilos generales del modal */
.modal-overlay {
    position: fixed; /* Fija el modal en la pantalla */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    display: flex; /* Usar flexbox para centrar */
    justify-content: center; /* Centrado horizontal */
    align-items: center; /* Centrado vertical */
    z-index: 1000; /* Asegúrate de que esté encima de otros elementos */
}

.modal-container {
    background-color: #ffffff;
    width: auto; /* Ancho ajustable para pantallas pequeñas */
    max-width: 800px; /* Máximo ancho */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px 30px;
    font-family: 'Arial', sans-serif;
    position: relative; /* Permite posicionar elementos internos */
    max-height: 90vh; /* Limita la altura al 90% de la ventana */
    overflow-y: auto; /* Agrega scroll si el contenido es muy alto */
}

.modal-container .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
}

/* Título (h2) */
.modal-container h2 {
    color: #3F63AC;
    margin-bottom: 20px;
    font-size: 24px;
}

/* Contenido del modal */
.modal-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

/* Información de asistencia */
.info-section {
    width: 60%;
}

.info-section p {
    margin: 8px 0;
    color: #4A4A4A;
    font-size: 16px;
}

.info-section p strong {
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #3F63AC;
}

/* Línea divisoria */
.divider {
    width: 1px;
    height: 100%;
    background-color: #E0E0E0;
    margin: 0 20px;
}

/* Sección de la imagen */
.image-section {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-section p {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
}

.profile-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #E0E0E0;
}

/* Evento */
.event-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
}

.event-icon {
    font-size: 16px;
    color: #8F3B8C;
}

.event-container p {
    margin: 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #666666;
}

.modal-container p strong {
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #3F63AC;
}


.contenedor-dispositivo {
    padding: 1rem 1.5rem;
}

.icon-background-true {
    position: relative;
    color: green;
}

.icon-background-false {
    position: relative;
    color: red;
}
.webcam-container {
    position: fixed;
    z-index: 9999;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.5rem 0;
    border-radius: 1rem;
    border: solid 0.25rem #2A9AB6;
}

.video {
    max-width: 100%;
    max-height: calc(100% - 20px);
    margin: 0 0.3rem;
}


@media only screen and (max-width: 500px) {
    .webcam-container{
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 1rem;
        width: 90vw;
        margin-top: -120px;
    }
}